import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'components/DataTable';
import { Card, SectionTitle } from 'components/Structure';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { services } from 'services';

export function RoutineList() {
    const [routines, setRoutines] = useState();

    useAlertedEffect(() => {
        if (routines) return;
        services.routines.getPage().then(res => setRoutines(res.results));
    }, [routines]);

    if (!routines) return '';

    return (
        <Card className="RoutineList">
            <SectionTitle>Routines</SectionTitle>
            {
                routines.length ?
                    <DataTable rows={
                        routines.map((r, i) => ([
                            <Link
                                to={ `/fitness/routines/${r.id}` }
                                children={ r.name }
                            />
                        ]))
                     } /> :
                    'No routines yet!'
            }
        </Card>
    );
}
