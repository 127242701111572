import React, { useState } from 'react';
import { Button, Select, Input } from 'components/Forms';
import { Card, SectionTitle } from 'components/Structure';
import { services } from 'services';

const DAY = 24 * 60 * 60 * 1000;

export function WeightEntry({page, refresh}) {
    const [date, setDate] = useState('Today');
    const [weight, setWeight] = useState(null);

    async function clickSave() {
        if (!weight || weight < 100 || weight > 250) return;

        const ts = date === 'Today' ? Date.now() : Date.now() - DAY;
        const payload = {
            ts: new Date(ts).toISOString().split('T')[0],
            value: weight,
        };

        const existing = page.results.find(w => w.ts === payload.ts);

        if (existing) {
            await services.weights.update({id: existing.id, ...payload});
        } else {
            await services.weights.create(payload);
        }

        setDate('Today');
        setWeight(null);
        setTimeout(() => refresh(), 100);
    }

    if (!page) return '';

    return (
        <Card className="WeightEntry">
            <SectionTitle>Log</SectionTitle>
            <Select
                choices={ ['Today', 'Yesterday'] }
                value={ date }
                onChange={ (ev) => setDate(ev.target.value) }
            />
            <Input
                value={ weight }
                onChange={ (ev) => setWeight(ev.target.value) }
            />
            <Button
                type="primary"
                onClick={ clickSave }
                children="Save"
            />
        </Card>
    )
}
