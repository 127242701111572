import React from 'react';
import './style.css';
import { groupBy, agg } from 'helpers';
import { Amount } from 'components/Amount';
import { Row } from 'components/Structure';
import { DataTable } from 'components/DataTable';

export function SummaryTable({ estimates, transactions }) {
    const trs = transactions.filter(t => !t.muted);

    const budgeted_regular_expenses = -1 * agg(estimates, {type: 1});
    const budgeted_income = agg(estimates, {type: 3});
    const budgeted_total = budgeted_regular_expenses + budgeted_income;

    const total_regular_expenses = agg(trs, {type: 1});
    const total_irregular_expenses = agg(trs, {type: 2});
    const total_income = agg(trs, {type: 3});
    const total_balance = agg(trs.filter(t => t.type !== 4), {});

    const total_untyped_expenses = agg(trs, {type: null});
    const net_actual_expenses = total_regular_expenses + total_irregular_expenses + total_untyped_expenses;

    // Net Margin: for budgeted categories, take the max of either the estimated
    // values or actual spending
    const groupedEstimates = groupBy(estimates.filter(e => e.type === 1), 'category'),
          groupedTransactions = groupBy(transactions.filter(e => e.type === 1), 'category');

    const net_margin = Object.keys(groupedEstimates).reduce((margin, category) => {
        const estimated = agg(groupedEstimates[category], {}),
              actual = -1 * agg(groupedTransactions[category], {});
        return margin - Math.max(estimated, actual);
    }, Math.max(budgeted_income, total_income) + total_irregular_expenses);

    const headers = ['', 'Budgeted', 'Actual'];

    return (
        <Row className="SummaryTable">
            <DataTable
                headers={ headers }
                rows={[
                    [
                        'Budgeted Expenses',
                        <Amount value={ budgeted_regular_expenses } />,
                        <Amount value={ total_regular_expenses } />
                    ],
                    [
                        'Unbudgeted Expenses',
                        '',
                        <Amount value={ total_irregular_expenses } />
                    ],
                    [
                        <strong children="Current Margin"/>,
                        '',
                        <Amount value={ net_margin } className="strong" />
                    ]
                ]}
            />
            <DataTable
                headers={ headers }
                rows={[
                    [
                        'Income',
                        <Amount value={ budgeted_income }/>,
                        <Amount value={ total_income }/>
                    ],
                    [
                        'Net Expenses',
                        <Amount value={ budgeted_regular_expenses }/>,
                        <Amount value={ net_actual_expenses }/>
                    ],
                    [
                        'Balance',
                        <Amount value={ budgeted_total }/>,
                        <Amount value={ total_balance }/>
                    ]
                ]}
            />
        </Row>
    );
}
