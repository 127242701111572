import React from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Card } from 'components/Structure';
import ReactECharts from 'echarts-for-react';

export function BudgetSummaryChart({ selectedAccount, transactionAgg }) {
    const history = useHistory();
    if (!transactionAgg) {
        return <Card style={{ minHeight: '220px', width: '100%' }} >&nbsp;</Card>;
    }

    const now = new Date();
    const options = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
      },
      grid: {
        top: 10,
        left: 50,
        right: 30,
        bottom: 30
      },
      yAxis: {
        type: 'value',
        position: 'top',
        splitLine: { show: false },
        min: 'dataMin',
        max: 'dataMax',
      },
      xAxis: {
        type: 'time',
        splitNumber: transactionAgg.length,
      },
      visualMap: {
        show: false,
        dimension: 1,
        pieces: [
          {
            lt: 0,
            color: '#D9534F'
          },
          {
            gt: 0,
            color: '#42E2B8'
          },
        ]
      },
      series: [
        {
          name: 'Total',
          type: 'bar',
          barWidth: '100%',
          stack: 'Total',
          data: transactionAgg.map((t) => {
            const item = {
                value: [
                    t.date.split('T')[0],
                    Math.round(t.amount * 100) / 100
                ],
            };

            const ts = new Date(t.date);
            if (ts.getMonth() === now.getMonth() && ts.getFullYear() === now.getFullYear()) {
                item.itemStyle = {
                    color: '#2D82B7'
                };
            }

            return item;
          })
        }
      ]
    };

    function onClick(ev) {
        const ts = DateTime.fromISO(ev.data.value[0]);
        history.push(`/budget/${selectedAccount.id}/${ts.toFormat('yyyy/LL')}`, {});
    }

    return (
        <Card style={{ width: '100%' }}>
            <ReactECharts
                option={options}
                style={{ height: '220px', width: '100%' }}
                onEvents={{ click: onClick }}
            />
        </Card>
    );
}
