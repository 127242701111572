import React, { useState } from 'react';
import './style.css';
import { Link, useHistory } from 'react-router-dom';
import { parse, stringify } from 'querystring';
import { Amount } from 'components/Amount';
import { Input, DateInput, Select } from 'components/Forms';
import { Pagination } from 'components/Pagination';
import { Page, Card } from 'components/Structure';
import { DataTable } from 'components/DataTable';
import { types, typeMap } from 'constants/types';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { makeMap } from 'helpers';
import { services } from 'services';

export function BudgetTransactionsList({ location }) {
    const [cats, setCats] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [params, setParams] = useState(parse(location.search.slice(1)));
    const [search, setSearch] = useState(params.search || '');
    const history = useHistory();

    useAlertedEffect(async () => {
        if (cats) return;
        const resp = await services.categories.getPage();
        setCats({categories: resp.results, map: makeMap(resp.results)});
    }, [cats]);

    useAlertedEffect(async () => {
        const resp = await services.transactions.getPage({...params, per_page: 100});
        setTransactions(resp);
    }, [params]);

    if (!transactions || !cats) return '';

    const rows = transactions.results.map((tr) => ([
        tr.posted.split('T')[0],
        tr.payee,
        tr.memo,
        <Amount value={ tr.amount} />,
        typeMap[tr.type],
        cats.map[tr.category],
        <Link to={ `/budget/review/${tr.id}` }>edit</Link>,
    ]));

    // TODO: Null value (no transactions) message
    rows.push([
        <strong>Total</strong>,
        '',
        '',
        <Amount value={ transactions.meta.total || 0 } />,
        '',
        '',
        '',
    ]);

    function update(field, value) {
        if (value && value !== '0') {
            params[field] = value;
        } else {
            Reflect.deleteProperty(params, field);
        }
        if (field !== 'page') {
            params.page = 1;
        }
        setParams({...params});
        history.replace(`/budget/transactions?${stringify(params)}`);
    }

    return (
        <Page className="BudgetTransactionsList" title="Transactions">
            <Card className="controls Forms mobile-hide">
                <DateInput
                    value={ new Date(params.posted__gte || '2017-10-01') }
                    onChange={ (ev) => update('posted__gte', ev.toISOString()) }
                    subtext="From"
                />
                <DateInput
                    value={ params.posted__lt ? new Date(params.posted__lt) : new Date() }
                    onChange={ (ev) => update('posted__lt', ev.toISOString()) }
                    subtext="To"
                />
                <Input
                    value={ search }
                    subtext="Search"
                    onChange={ (ev) => setSearch(ev.target.value) }
                    onKeyUp={ (ev) => {
                        if (ev.code === 'Enter') {
                            update('search', ev.target.value)
                        }
                    }}
                />
                <Select
                    value={ params.category }
                    subtext="Category"
                    onChange={ (ev) => update('category', ev.target.value) }
                    choices={ [{name: '', id: 0}, ...cats.categories] }
                />
                <Select
                    value={ params.type }
                    subtext="Type"
                    onChange={ (ev) => update('type', ev.target.value) }
                    choices={ [{name: '', id: 0}, ...types] }
                />
            </Card>

            <Card>
                <DataTable
                    headers={ ['Date', 'Payee', 'Memo', 'Amount', 'Type', 'Category', 'Edit'] }
                    rows={ rows }
                />

                <Pagination
                    page={ parseInt(params.page) || 1 }
                    per_page={ 100 }
                    count={ transactions.count }
                    onChange={ (page) => update('page', page) }
                />
            </Card>
        </Page>
    );
}
