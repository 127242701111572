import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { stringify } from 'querystring';
import { Link } from 'react-router-dom';
import { Input } from 'components/Forms';
import { Card, SectionTitle } from 'components/Structure';

export function TransactionSearch({ selectedAccount }) {
    const [search, setSearch] = useState('');
    const history = useHistory();
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;

    return (
        <Card>
            <SectionTitle children="Search Transactions" />
            <Input
                value={ search }
                onChange={ (ev) => setSearch(ev.target.value) }
                onKeyUp={ (ev) => {
                    if (ev.code === 'Enter') {
                        const qs = stringify({search});
                        history.push(`/budget/transactions?${qs}`, {});
                    }
                }}
                placeholder="Search"
            />
            <div>
                <Link to={ `/budget/${selectedAccount?.id}/${year}/${month}` }>Current Budget</Link>
            </div>
            <div>
                <Link to={ `/budget/report` }>Past Year Averages</Link>
            </div>
        </Card>
    );
}
