import React from 'react';
import './style.css';

export function Progress({value, max, type}) {
    return (
        <div className="Progress">
            <div
                className={ `progress-bar progress-${type}` }
                style={{ width: ((value / max) * 100).toFixed(1) + '%' }}
            />
        </div>
    );
}
