export function sortBy(list, iteratee) {
    return [...list].sort((a, b) => a[iteratee] > b[iteratee] ? 1 : -1);
}

export function makeMap(list) {
    return list.reduce((res, item) => {
        res[item.id] = item.name;
        return res;
    }, {});
}

export function groupBy(list, key) {
    return list.reduce((result, item) => {
        const k = item[key];
        if (!result[k]) result[k] = [];
        result[k].push(item);
        return result;
    }, {});
}

export function filter(list, filters) {
    return (list || []).filter((item) => {
        return Object.keys(filters).every(k => item[k] === filters[k]);
    });
}

export function agg(list, filters) {
    const sublist = filter(list, filters);
    return sublist.reduce((result, item) => result + item.amount, 0);
}

export function orderBy(list, predicates) {
    const l = [...list];
    l.sort((a, b) => {
        for (const pred of predicates) {
            if (a[pred] === b[pred]) continue;
            return (a[pred] > b[pred] ? -1 : 1)
        }
        return 1;
    });
    return l;
}
