import React, { useState, useContext  } from 'react';
import { NavLink } from 'react-router-dom';
import './style.css'
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from 'context/AppContext';
import { services } from 'services';

export function Menu() {
    const { currentUser } = useContext(AppContext);

    const [open, setOpen] = useState(false);
    const admin = currentUser.is_staff ? <a className="bordered" href="/admin/">Site Admin</a> : '';

    function signout(ev) {
        services.auth.logout();
    }

    if (!currentUser) {
        return '';
    }

    return (
        <div className='Menu mobile-show'>
            <div className="hamburger">
                <FontAwesomeIcon icon={ faBars } onClick={ () => setOpen(true) }/>
            </div>
            <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
                <div className={'menu-content ' + (open ? 'menu-open' : 'menu-closed') }>
                    <div className="menu-inner" onClick={ () => setOpen(false) } >
                        <div className="menu-close-button">
                            <FontAwesomeIcon icon={ faTimes } />
                        </div>
                        <NavLink to="/budget" activeClassName="active">Budget</NavLink>
                        <NavLink to="/fitness" activeClassName="active">Fitness</NavLink>
                        <NavLink to="/meters" activeClassName="active">Meters</NavLink>
                        <NavLink to="/lists" activeClassName="active">Lists</NavLink>
                        { admin }
                        <a href="/" className="bordered" onClick={ signout }>
                            Sign out
                        </a>
                    </div>
                </div>
            </OutsideClickHandler>
        </div>
    );
}
