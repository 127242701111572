import { stringify } from 'querystring';
import { AuthService } from './auth';
import { CacheService } from './cache';

export class BaseService {
    cachePeriod = 10 * 1000;

    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        this.cache = new CacheService();
        this.auth = new AuthService();
    }

    getPage(query) {
        const qs = stringify(query);
        const url = `${this.baseUrl}?${qs}`

        let cached = this.cache.get(url);
        const headers = this.getHeaders();
        if (cached) {
            headers['If-Modified-Since'] = new Date(cached.fetched).toISOString();
            headers['X-Count'] = '' + cached.count;
        }

        return this.request(url, {headers}).then(async (res) => {
            if (res.status === 304) {
                // Re-store the with an updated fetched time
                this.cacheResults(url, res, cached);
                return cached;
            }
            const body = await res.json();
            const response = {query, ...body};
            this.cacheResults(url, res, response);
            return response;
        });
    }

    cacheResults(url, res, results) {
        results.fetched = new Date(res.headers.get('Date')).valueOf();
        this.cache.set(url, results);
    }

    getById(id) {
        return this.request(`${this.baseUrl}${id}/`, {
            headers: this.getHeaders()
        })
        .then(res => res.json());
    }

    create(obj) {
        return this.request(this.baseUrl, {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(obj)
        })
        .then(res => res.json());
    }

    update(obj) {
        return this.request(`${this.baseUrl}${obj.id}/`, {
            method: 'PUT',
            headers: this.getHeaders(),
            body: JSON.stringify(obj)
        })
        .then(res => res.json());
    }

    removeById(id) {
        return this.request(`${this.baseUrl}${id}/`, {
            method: 'DELETE',
            headers: this.getHeaders(),
        });
    }

    remove(obj) {
        return this.removeById(obj.id);
    }

    getAgg(query) {
        const qs = stringify(query || {});
        return this.request(`${this.baseUrl}agg/?${qs}`, {
            headers: this.getHeaders()
        })
        .then(res => res.json())
        .then(res => res.results);
    }

    request(url, options) {
        return fetch(url, options)
            .then((res) => {
                if (res.status >= 400) throw res;
                return res;
            })
    }

    getHeaders() {
        return {
            Authorization: 'JWT ' + this.auth.getToken(),
            'Content-Type': 'application/json',
            // 'X-CSRFToken': this.token
        };
    }
}

