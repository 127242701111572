import React, { useState } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import { Page, Card, SidePanelList } from 'components/Structure';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { services } from 'services';
import { ListDetails, loadList } from 'pages/ListDetails';

export function ListsHome() {
    const history = useHistory();
    const [lists, setLists] = useState(null);
    const [selectedList, setSelectedList] = useState(null);

    useAlertedEffect(async () => {
        if (lists) return;
        return services.lists.getPage().then(res => setLists(res.results));
    }, [lists]);

    if (!lists) return '';

    if (lists.length && !selectedList) {
        const id = lists[0].id;
        loadList(id, setSelectedList);
        return '';
    }

    function select(listId) {
        const mobile = window.innerWidth < 512;
        if (mobile) {
            return history.push(`/lists/${listId}`, {});
        }
        history.replace(`/lists/?show=${listId}`);
        loadList(listId, setSelectedList);
    }

    return (
        <Page className="ListsHome" title="Lists">
            <SidePanelList
                left={
                    <Card className="lists">
                        {
                            lists.length ?
                                lists.map((r, i) => (
                                    <div
                                        className="item-list-row"
                                        onClick={ () => select(r.id) }
                                        children={ r.name }
                                        key={ `row-${i}` }
                                    />
                                )) :
                                'No lists yet!'
                        }
                    </Card>
                }
                right={
                    selectedList ?
                        <ListDetails list={ selectedList } setList={ setSelectedList } /> :
                        <Card>Create a list to get started.</Card>
                }
            />
        </Page>
    );
}
