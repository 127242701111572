import React from 'react';
import { Pagination } from 'components/Pagination';
import { Card } from 'components/Structure';
import { DataTable } from 'components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { services } from 'services';

export function WeightTable({page, refresh}) {
    if (!page) return '';

    async function deleteWeight(w) {
        await services.weights.remove(w);
        refresh();
    }

    const rows = page.results.map((w, i) => [
        w.ts,
        <>
            { w.value.toFixed(1) }
            <FontAwesomeIcon
                icon={ faTimes }
                onClick={ () => deleteWeight(w) }
            />
        </>
    ]);
    return (
        <Card className="WeightTable mobile-hide">
            <DataTable headers={ ['Date', 'Lbs'] } rows={rows} />
            <Pagination
                page={ page?.query?.page || 1 }
                per_page={ page?.query?.per_page || 20 }
                count={ page.count }
                onChange={ (pageNum) => refresh({page: pageNum}) }
            />
        </Card>
    );
}
