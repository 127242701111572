import React from 'react';
import { DateTime } from 'luxon';
import ReactECharts from 'echarts-for-react';

/*
const buckets = {
    year: 'y',
    month: 'L/yy',
    week: 'D',
    day: 'L/d',
    hour: 'H',
};
*/

const sensorNames = {
    electricity: 'Electricity',
    naturalgas: 'Natural Gas',
}

const sensorColors = {
    electricity: '#2D82B7',
    naturalgas: '#42E2B8',
}

export function ReadingsChart({ readings, params, setParams }) {
    if (!readings) {
        return <div style={{ minHeight: '220px' }} />;
    }

    const options = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
      },
      grid: {
        top: 20,
        left: 20,
        right: 40,
        bottom: 20
      },
      yAxis: {
        type: 'value',
        position: 'right',
        splitLine: { show: false },
        min: 0,
        max: 'dataMax',
      },
      xAxis: {
        type: 'time',
        splitNumber: readings.length,
      },
      visualMap: {
        show: false,
        dimension: 1,
        pieces: [
          {
            gte: 0,
            color: sensorColors[params.sensor]
          },
        ]
      },
      series: [
        {
          name: sensorNames[params.sensor],
          type: 'bar',
          barWidth: '90%',
          data: readings.map((t) => {
            const item = {
                value: [
                    t.ts,
                    Math.round(t.value * 100) / 100
                ],
            };
            return item;
          })
        }
      ]
    };

    function onClick(ev) {
        const ts = ev.data.value[0];
        setParams({
            sensor: params.sensor,
            ts__gte: DateTime.fromISO(ts).toISO(),
            ts__lt: DateTime.fromISO(ts).plus({[params.bucket]: 1}).toISO(),
        });
    }

    return (
        <div
            style={{ width: '100%', minWidth: '600px' }}
            className="ReadingsChart"
        >
            <ReactECharts
                option={options}
                style={{ height: '220px', width: '100%' }}
                onEvents={{ click: onClick }}
            />
        </div>
    );
}
