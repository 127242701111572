import React, { useState } from 'react';
import './style.css';
import { parse, stringify } from 'querystring';
import { useHistory } from 'react-router-dom';
import { DateTime, Duration } from 'luxon';
import { Page, Card, Row } from 'components/Structure';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { services } from 'services';
import { ReadingsChart } from './ReadingsChart';
import { ReadingsParams } from './ReadingsParams';
import { ReadingsPresets } from './ReadingsPresets';
import { orderBy } from 'helpers';

export function Meters({ location }) {
    const now = DateTime.local();
    const defaults = {
        ts__lt: now.startOf('day').plus({days: 1}).toISO(),
        ts__gte: now.startOf('day').plus({days: 1}).minus({months: 1}).startOf('day').toISO(),
    };
    const qs = parse(location.search.slice(1));

    const history = useHistory();
    const [readings, setReadings] = useState(null);
    const [params, setParams] = useState({...defaults, ...qs});

    useAlertedEffect(async () => {
        // Ensure we set the bucket param
        if (!params.bucket) {
            return updateParams({});
        }

        const [electricity, naturalgas] = await Promise.all([
            services.readings.getAgg({...params, sensor: 'electricity'}),
            services.readings.getAgg({...params, sensor: 'naturalgas'}),
        ]);
        const combined = {electricity, naturalgas};

        // Ensure we include 0-valued buckets
        Object.keys(combined).forEach((sensor) => {
            let iterator = DateTime.fromISO(params.ts__gte).startOf(params.bucket);
            const end = DateTime.fromISO(params.ts__lt);
            while (iterator.valueOf() < end.valueOf()) {
                const ts = iterator.toISO().replace('.000', '');
                const match = combined[sensor].find(r => r.ts === ts);
                if (!match) {
                    combined[sensor].push({value: 0, ts});
                }
                iterator = iterator.plus({[params.bucket]: 1});
            }
            combined[sensor] = orderBy(combined[sensor], ['ts']).reverse();
        });
        setReadings(combined);
    }, [params]);

    function updateParams(updates) {
        const newParams = {...params, ...updates};
        setReadings(null);

        const start = DateTime.fromISO(newParams.ts__gte);
        const end = DateTime.fromISO(newParams.ts__lt);
        const diff = end - start;
        newParams.bucket = 'year';
        if (diff <= Duration.fromObject({hours: 25})) {
            newParams.bucket = 'hour';
        } else if (diff <= Duration.fromObject({days: 32})) {
            newParams.bucket = 'day';
        } else if (diff <= Duration.fromObject({days: (3 * 365) + 1})) {
            newParams.bucket = 'month';
        }

        setParams(newParams);
        const qs = stringify(newParams);
        history.push(`/meters?${qs}`);
    }

    return (
        <Page className="Meters" title="Meters">
            <Card style={{ marginBottom: '20px' }}>
                <Row>
                    <ReadingsPresets
                        params={ params }
                        setParams={ updateParams }
                    />
                    <ReadingsParams
                        params={ params }
                        setParams={ updateParams }
                    />
                </Row>
            </Card>
            <Card className="ChartCard" style={{ marginBottom: '20px' }}>
                <ReadingsChart
                    readings={ readings?.electricity }
                    params={ {...params, sensor: 'electricity'} }
                    setParams={ updateParams }
                />
            </Card>
            <Card className="ChartCard" >
                <ReadingsChart
                    readings={ readings?.naturalgas }
                    params={ {...params, sensor: 'naturalgas'} }
                    setParams={ updateParams }
                />
            </Card>
        </Page>
    );
}
