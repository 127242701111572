import React from 'react';
import { Redirect } from 'react-router-dom';


export function Home() {
    // Just redirect to the budget page for now
    return (
        <Redirect to="/budget" />
    );
}
