import React, { useEffect, useState } from 'react';
import { csvParseRows } from 'd3-dsv';
import { useHistory } from 'react-router-dom';
import { FileInput } from 'components/Forms';
import { Progress } from 'components/Progress';
import { services } from 'services';
import parsers from './parsers';

export function parseRow(parser, account, row, rules) {
    const parsed = parser(row);
    if (!parsed) return;

    const rule = rules.find(r => r.payee === parsed.payee);

    return {
        ...parsed,
        account: account.id,
        muted: false,
        category: rule?.category || null,
        type: rule?.type || null,
    };
}

function FileSelect({ onChange }) {
    return (
        <div className="FileSelect" style={{ marginBottom: 10 }}>
            <FileInput
                label="Upload CSV File"
                id="transaction-upload"
                onChange={ onChange }
            />
        </div>
    );
}

function Uploading({ rows, uploaded }) {
    return (
        <div>
            <Progress
                value={ uploaded }
                max={ rows.length || 1 }
                type="info"
            />
        </div>
    );
}

export function AccountSync({ selectedAccount, done }) {
    const history = useHistory();
    const [meta, setMeta] = useState(null);
    const [uploaded, setUploaded] = useState(0);
    const [rows, setRows] = useState(null);

    useEffect(() => {
        if (meta) return;
        Promise.all([
            services.transactions.getPage({per_page: 1}),
            services.rules.getPage(),
        ]).then(([transactionResp, ruleResp]) => {
            const p = transactionResp.results[0]?.posted || '1970-01-01T00:00:00Z';
            setMeta({
                lastPosted: new Date(p),
                rules: ruleResp.results,
            });
        });
    }, [meta]);

    function fileChange(ev) {
        const file = ev.target.files[0];
        const reader = new FileReader();

        reader.onload = async function(e) {
            const rows = csvParseRows(e.target.result);
            const parser = parsers[selectedAccount.institution.name];

            const payloads = rows
                .map(row => parseRow(parser, selectedAccount, row, meta.rules))
                .filter(payload => payload && payload.posted > meta.lastPosted);
            setRows(payloads);

            for (const index in payloads) {
                await services.transactions.create(payloads[index]);
                setUploaded(parseInt(index) + 1);
            }
            history.push('/budget/review', {});
        };
        reader.readAsText(file);
    }

    let content = '';
    if (!rows) {
        content = <FileSelect onChange={ fileChange } />
    } else {
        content = <Uploading rows={ rows } uploaded={ uploaded } />
    }

    return <div children={ content } />;
}
