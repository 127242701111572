import React, { useState } from 'react';
import './style.css';
import { Page, Column, Card, CardRow, SectionTitle } from 'components/Structure';
import { Select } from 'components/Forms';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { services } from 'services';
import { AccountSync } from './AccountSync';
import { BudgetSummaryChart } from './BudgetSummaryChart';
import { TransactionSearch } from './TransactionSearch';

function getParams() {
    const now = new Date();
    const start = new Date(now.getFullYear() - 2, now.getMonth(), 1);
    return {posted__gt: start.toISOString()};
}

export function BudgetHome() {
    const [accounts, setAccounts] = useState(null);
    const [transactionAgg, setTransactionAgg] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);

    useAlertedEffect(async () => {
        if (accounts) return;
        const { results }= await services.accounts.getPage({})
        setAccounts(results);
        const primaryAccount = results.find(a => a.account_type === 'CHECKING' && a.primary);
        setSelectedAccount(primaryAccount);
    }, [accounts]);

    useAlertedEffect(async () => {
        if (!accounts || transactionAgg) return;
        const aggs = await services.transactions.getAgg(getParams())
        setTransactionAgg(aggs);
    }, [accounts, transactionAgg]);

    function refreshAggs() {
        services.transactions
            .getAgg(getParams())
            .then((aggs) => setTransactionAgg(aggs));
    }

    if (!accounts) return '';

    return (
        <Page className="BudgetHome" title="Budget">
            <CardRow className="ChartCard mobile-hide">
                <BudgetSummaryChart
                    selectedAccount={ selectedAccount }
                    transactionAgg={ transactionAgg }
                />
            </CardRow>
            <CardRow className="budget-main">
                <Column>
                    <Card className="account-select">
                        <SectionTitle children="Select an Account" />
                        <Select
                            id="account"
                            choices={ accounts }
                            value={ selectedAccount?.id }
                            onChange={ (ev) => {
                                const id = parseInt(ev.target.value);
                                setSelectedAccount(accounts.find(a => a.id === id));
                            }}
                        />
                        <AccountSync
                            selectedAccount={ selectedAccount }
                            done={ refreshAggs }
                        />
                    </Card>
                </Column>
                <Column>
                    <TransactionSearch
                        selectedAccount={ selectedAccount }
                    />
                </Column>
            </CardRow>
        </Page>
    );
}
