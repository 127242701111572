function usaa(row) {
    if (row[0] === 'posted') {
        const [month, day, year] = row[2].split('/');
        return {
            posted: new Date(`${year}-${month}-${day}T12:00:00Z`),
            payee: row[4].split('~~')[0].split('****')[0].trim(),
            id: crypto.randomUUID(),
            amount: parseFloat(JSON.parse(row[6])),
            transaction_type: '-',
        };
    } else if (row[5] === 'Posted') {
        return {
            posted: new Date(`${row[0]}T12:00:00Z`),
            payee: row[1].trim(),
            id: crypto.randomUUID(),
            amount: parseFloat(JSON.parse(row[4])),
            transaction_type: '-',
        };
    }
}

function gsb(row) {
    if (row[6].trim().endsWith('(PENDING)')) {
        return;
    }
    if (row[0] === 'Account') {
        return;
    }

    const [month, day, year] = row[5].split('/');
    const amount = row[2] ? -1 * row[2] : row[3];

    let description = row[6];
    const descriptionIndex = description.indexOf(' US  ');
    if (descriptionIndex > 0) description = row[6].slice(0, descriptionIndex) + ' US';

    return {
        posted: new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T12:00:00Z`),
        payee: description,
        id: crypto.randomUUID(),
        amount: parseFloat(JSON.parse(amount)),
        transaction_type: '-',
    };
}

const parsers = {USAA: usaa, GSB: gsb};
export default parsers;
