import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'components/Forms';
import { Column, Row, Card } from 'components/Structure';

export function TitleCard({ accountId, budget, start, end, unprocessed, setEditEstimate }) {
    const previous = start.minus({months: 1});

    let button = '';
    if (budget) {
        button = (
            <Button
                type="outline small"
                onClick={ () => setEditEstimate({budget: budget.id, type: 1, category: 1}) }
            >
                <FontAwesomeIcon icon={ faPlus }/>&nbsp;Add new estimate
            </Button>
        );
    }

    let link = '';
    if (unprocessed) {
        link = (
            <Link to="/budget/review">
                Review { unprocessed } { unprocessed === 1 ? 'transaction' : 'transactions' }
            </Link>
        );
    }

    return (
        <Card className="TitleCard">
            <Row style={{ justifyContent: 'space-between' }}>
                <Link to={ `/budget/${ accountId }/${ previous.toFormat('y/L') }` } className="">
                    <FontAwesomeIcon icon={ faAngleLeft }/> { previous.toFormat('MMMM') }
                </Link>
                <Link to={ `/budget/${ accountId }/${ end.toFormat('y/L') }` }>
                    { end.toFormat('MMMM') } <FontAwesomeIcon icon={ faAngleRight }/>
                </Link>
            </Row>
            <Column>
                <Link to={ `/budget/transactions?posted__gte=${ start.toISO() }&posted__lt=${ end.toISO() }` }>
                    All transactions this month
                </Link>
                { button }
                { link }
            </Column>

        </Card>
    );
}
