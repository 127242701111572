import React, { useState } from 'react';
import { Modal } from 'components/Modal';
import { Input, Select, Button, CheckBox, ButtonRow } from 'components/Forms';
import { Card, Row } from 'components/Structure';
import { types } from 'constants/types';
import { services } from 'services';

export function EstimateEditModal({ editEstimate, setEditEstimate, categories, refresh }) {
    const show = Boolean(editEstimate);
    const [loading, setLoading] = useState(false);

    async function remove() {
        setLoading(true);
        await services.estimates.remove(editEstimate);
        setLoading(false);
        setEditEstimate(null);
        refresh();
    }

    async function save() {
        if (!show) return;
        await setLoading(true);

        if (editEstimate.id) {
            await services.estimates.update(editEstimate);
        } else {
            await services.estimates.create(editEstimate);
        }
        setLoading(false);
        setEditEstimate(null);
        refresh();
    }

    return (
        <Modal
            title={ editEstimate?.id ? 'Edit Estimate' : editEstimate ? 'Add an Estimate' : '' }
            show={ show }
            close={ () => setEditEstimate(null) }
            body={
                <Card>
                    <Input
                        label="Description"
                        value={ editEstimate?.description }
                        onChange={ (ev) => setEditEstimate({...editEstimate, description: ev.target.value }) }
                    />
                    <Input
                        label="Amount"
                        type="number"
                        value={ editEstimate?.amount }
                        onChange={ (ev) => setEditEstimate({...editEstimate, amount: ev.target.value }) }
                    />
                    <Select
                        label="Type"
                        value={ editEstimate?.type || 0 }
                        onChange={ (ev) => setEditEstimate({...editEstimate, type: ev.target.value }) }
                        choices={ types }
                        select="id"
                        display="name"
                    />
                    <Select
                        label="Category"
                        value={ editEstimate?.category || 0 }
                        onChange={ (ev) => setEditEstimate({...editEstimate, category: ev.target.value }) }
                        choices={ categories }
                        select="id"
                        display="name"
                    />
                    <Row className="rule-section">
                        <CheckBox
                            value={ editEstimate?.adhoc }
                            onClick={ () => setEditEstimate({...editEstimate, adhoc: !editEstimate.adhoc }) }
                            label="Skip When Copying Budgets"
                        />
                    </Row>
                    <ButtonRow style={{ paddingBottom: 0, flexDirection: 'row-reverse' }}>
                        <Button type="primary" onClick={ save } children="Save" disabled={ loading }/>
                        { editEstimate?.id ? <Button type="danger" onClick={ remove } children="Delete" disabled={ loading }/> : '' }
                    </ButtonRow>
                </Card>
            }
        />
    );
}
