import React from 'react';
import { stringify } from 'querystring';
import { DateTime } from 'luxon';
import { Amount } from 'components/Amount';
import { DataTableRow, DataTable } from 'components/DataTable';
import { Card } from 'components/Structure';
import { LinkedSectionTitle } from '../LinkedSectionTitle';
import { Total } from '../Total';
import { services } from 'services';

export function TransactionSection({ name, start, end, type, catMap, transactions, updateContent }) {
    const trs = transactions.filter(t => t.type === type)

    async function muteTransaction(transaction) {
        transaction.muted = !transaction.muted;
        services.transactions.update(transaction);
        updateContent({transactions});
    }

    const rows = trs.map((t, i) => (
        <DataTableRow
            row={ [
                DateTime.fromISO(t.posted).toFormat('MM/dd/yy'),
                t.payee,
                t.memo,
                <Amount value={ t.amount } />
            ] }
            key={ `tr-${i}` }
            onClick={ () => muteTransaction(t) }
            className={ t.muted ? 'muted' : '' }
        />
    ));

    const qs = stringify({
        type,
        posted__gte: start.toISO(),
        posted__lt: end.toISO(),
    });

    return (
        <Card className="TransactionSection">
            <LinkedSectionTitle name={ name } qs={ qs }/>
            <Total name="Actual" group={ trs } filters={{ muted: false }}/>
            <DataTable
                headers={ [] }
                rows={ rows }
            />
            {
                rows.length === 0 ?
                    <p className="muted">{ `No ${ name.toLowerCase() } recorded yet` }</p>:
                    ''
            }
        </Card>
    );
}
