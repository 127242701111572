import React, { useState } from 'react';
import { Input } from 'components/Forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { SectionTitle, Placeholder } from 'components/Structure';
import { groupBy } from 'helpers';
import { ItemEditModal } from '../ItemEditModal';

function ListItem({ item, checkItem, updateItem, setEditItem }) {
    const [name, setName] = useState(item.name);

    function onBlur(ev) {
        if (ev.target.value !== item.name) {
            updateItem({...item, name: ev.target.value});
        }
    }

    return (
        <div className={ 'ListItem' + (item.checked ? ' checked' : '' )}>
            <FontAwesomeIcon
                className="check-box"
                icon={ item.checked ? faCheckSquare : faSquare }
                onMouseDown={ () => checkItem(item) }
            />
            <Input
                value={ name }
                onChange={ (ev) => setName(ev.target.value) }
                onBlur={ (ev) => onBlur(ev) }
            />
            <FontAwesomeIcon
                className="item-menu"
                icon={ faEllipsisV }
                onMouseDown={ () => setEditItem(item) }
            />
        </div>
    );
}

function ListItemGroup({ title, items, checkItem, updateItem, setEditItem }) {
    return (

        <div className="ListItemGroup">
            <SectionTitle>{ title }</SectionTitle>
            {
                (items || []).map((item) => (
                    <ListItem
                        item={ item }
                        key={ `item-${item.id}` }
                        checkItem={ checkItem }
                        updateItem={ updateItem }
                        setEditItem={ setEditItem }
                    />
                ))
            }
        </div>
    );
}

export function ListItems({ items, group_by_location, checkItem, updateItem }) {
    const [editItem, setEditItem] = useState();

    let groups = {};
    const showItems = items.filter(i => i.show);
    if (group_by_location) {
        groups = groupBy(showItems, 'location');
        if (groups['null']) {
            groups['Unknown'] = groups['null'];
            Reflect.deleteProperty(groups, 'null');
        }
    } else if (showItems.length) {
        groups = {'': showItems};
    }
    const groupKeys = Object.keys(groups).sort((a, b) => {
        if (a.startsWith('Aisle ') && b.startsWith('Aisle ')) {
            return parseInt(a.slice(6)) > parseInt(b.slice(6)) ? 1 : -1;
        }
        return a > b ? -1 : 1;
    });

    // Group by location
    return (
        <div className="ListItems">
            {
                groupKeys.map((title, index) => (
                    <ListItemGroup
                        title={ title }
                        items={ groups[title] }
                        key={ `item-group-${index}` }
                        checkItem={ checkItem }
                        updateItem={ updateItem }
                        setEditItem={ setEditItem }
                    />
                ))
            }
            <Placeholder
                condition={ groupKeys.length === 0 }
                children="No items in this list"
            />
            <ItemEditModal
                item={ editItem }
                updateItem={ updateItem }
                setEditItem={ setEditItem }
            />
        </div>
    );
}
