import React from 'react';
import { DateInput } from 'components/Forms';
import { Row } from 'components/Structure';


export function ReadingsParams({ params, setParams }) {
    return (
        <Row className="ReadingsParams">
            <DateInput
                value={ new Date(params.ts__gte) }
                onChange={ (ev) => setParams({...params, ts__gte: ev.toISOString() }) }
                subtext="From"
            />
            <DateInput
                value={ new Date(params.ts__lt) }
                onChange={ (ev) => setParams({...params, ts__lt: ev.toISOString() }) }
                subtext="To"
            />
        </Row>
    );
}
