import React, { useState, useEffect } from 'react';
import { Button, Select } from 'components/Forms';
import { Card } from 'components/Structure';
import { services } from 'services';

export function CreateBudget({ account_id, year, month, refresh }) {
    const [budgets, setBudgets] = useState(null);
    const [cloneId, setCloneId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        services.budgets.getPage({account_id}).then((resp) => {
            let budgets = resp.results.reverse();
            budgets.forEach(b => b.name = `${b.year}/${b.month}`);
            setCloneId(budgets[0]?.id);
            setBudgets(budgets);
        });
    }, [account_id]);

    async function save() {
        setLoading(true);
        const payload = {account: account_id, year, month};
        const created = await services.budgets.create(payload);

        const toClone = budgets.find(b => b.id === cloneId);
        const estimates = toClone.estimate_set
            .filter(estimate => !estimate.adhoc)
            .map((estimate) => ({...estimate, budget: created.id, id: undefined}));
        for (const est of estimates) {
            await services.estimates.create(est);
        }
        refresh();
    }

    console.log(account_id);

    if (!budgets) return '';

    return (
        <Card className="CreateBudget">
            <Select
                choices={ budgets }
                value={ cloneId }
                label="Clone a previous budget"
                onChange={ (ev) => setCloneId(parseInt(ev.target.value)) }
            />
            <Button
                type="primary"
                onClick={ () => save() }
                children="Create"
                disabled={ loading }
            />
        </Card>
    );
}
