import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { SectionTitle } from 'components/Structure';

export function LinkedSectionTitle({ name, qs, className }) {
    return (
        <SectionTitle className={ className }>
            { name }
            <Link to={ `/budget/transactions?${qs}` } style={{ marginLeft: '5px' }}>
                <FontAwesomeIcon icon={ faAngleRight }/>
            </Link>
        </SectionTitle>
    );
}
