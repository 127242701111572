import React, { useState, useEffect } from 'react';
import { speak } from 'services/speech';

function padLeft(number) {
    return ('0' + number).slice(-2);
}

function formatTime(seconds) {
    return `${padLeft(Math.floor(seconds / 60))}:${padLeft(seconds % 60)}`;
}

const REST_STRINGS = [
    'OK, rest',
    'Good, now rest',
    'Great work',
];

// "Next" button
// Total progress?
export function RunningMode({ routine, setMode }) {
    const [state, setState] = useState({
        index: 0,
        totalMs: 0,
        durationMs: routine.movement_set[0]?.duration * 1000,
        cooldown: false,
    });
    const [paused, setPaused] = useState(false);

    useEffect(() => {
        function func(ev) {
            if (ev.key === ' ') {
                setPaused(!paused)
            }
        };
        document.addEventListener("keydown", func);
        return () => document.removeEventListener("keydown", func);
    }, [paused, setPaused]);

    useEffect(() => {
        if (!routine.movement_set[state.index]) {
            setMode('complete');
            return;
        }

        // This proceeds us to the next move.
        if (state.durationMs === 0) {
            const cooldown = !state.cooldown && routine.movement_set[state.index].cooldown;
            const nextIndex = cooldown ? state.index : state.index + 1;
            const move = routine.movement_set[nextIndex];
            const duration = cooldown ? move?.cooldown : move?.duration;

            setTimeout(() => {
                setState({
                    index: nextIndex,
                    durationMs: duration * 1000,
                    cooldown: cooldown,
                })
                }, duration ? 200 : 0
            );
            return;
        }

        // If we're not paused, then decrement the durationMs for the counter
        // and speak the name of the move if it's at the start of a change
        let interval;
        if (!paused) {
            const current = routine.movement_set[state.index];
            if (!window.speechSynthesis.speaking) {
                if (!state.cooldown && state.durationMs === current?.duration * 1000) {
                    speak(current.name);
                } else if (state.cooldown && state.durationMs === current?.cooldown * 1000) {
                    speak(REST_STRINGS[Math.floor(Math.random() * REST_STRINGS.length)]);
                }
            }

            const durationMs = state.durationMs - 100;
            const totalMs = state.totalMs + 100;

            interval = setTimeout(() => setState({...state, durationMs, totalMs}), 100)
        }

        // Capture left and right arrows to navigate/skip moves
        function func(ev) {
            if (ev.key === 'ArrowLeft') {
                clearInterval(interval);
                const current = routine.movement_set[state.index];
                const diff = (current.duration * 1000) - state.durationMs;
                let nextIndex = state.index;
                if (!state.cooldown && (diff < 5000) && state.index > 0) {
                    nextIndex = state.index - 1;
                }
                setState({
                    ...state,
                    index: nextIndex,
                    durationMs: routine.movement_set[nextIndex]?.duration * 1000,
                    cooldown: false,
                });
            } else if (ev.key === 'ArrowRight') {
                clearInterval(interval);
                setState({
                    ...state,
                    index: state.index + 1,
                    durationMs: routine.movement_set[state.index + 1]?.duration * 1000,
                    cooldown: false,
                });
            }
        };

        document.addEventListener("keydown", func);
        return () => document.removeEventListener("keydown", func);
    }, [routine.movement_set, paused, state, setState, setMode]);

    const current = routine.movement_set[state.index];
    const upNext = routine.movement_set[state.index + 1];

    if (!current) {
        return '';
    }

    const remaining = routine.total
                      - current.start
                      - current.duration
                      - (state.cooldown ? current.cooldown : 0)
                      + Math.ceil(state.durationMs / 1000)

    return (
        <div className="RunningMode" style={{ opacity: paused ? '50%' : '100%' }}>
            <div className={ `move-title${ state.cooldown ? ' cooldown' : '' }` }>
                { state.cooldown ? 'Rest' : current.name }
            </div>
            <div id="timer" className={ state.cooldown ? 'cooldown' : '' }>
                { formatTime(Math.ceil(state.durationMs / 1000)) }
            </div>
            <div className="bottom-row">
                <div className="up-next">
                    Next: { upNext ? upNext.name : 'Done!' }
                </div>
                <div className="remaining">
                    { formatTime(remaining) }
                </div>
            </div>
        </div>
    );
}
