import { AuthService } from './auth';
import { BaseService } from './base';

export const services = {
    auth: new AuthService(),
    accounts: new BaseService('/api/v1/accounts/'),
    budgets: new BaseService('/api/v1/budgets/'),
    categories: new BaseService('/api/v1/categories/'),
    estimates: new BaseService('/api/v1/estimates/'),
    rules: new BaseService('/api/v1/rules/'),
    readings: new BaseService('/api/v1/readings/'),
    routines: new BaseService('/api/v1/routines/'),
    transactions: new BaseService('/api/v1/transactions/'),
    lists: new BaseService('/api/v1/lists/'),
    listItems: new BaseService('/api/v1/list-items/'),
    secrets: new BaseService('/api/v1/secrets/'),
    users: new BaseService('/api/v1/users/'),
    weights: new BaseService('/api/v1/weight/'),
}
