import React, { useState, useEffect } from 'react';
import { Loading } from 'components/Loading';

export function PullDown({ onPullDown }) {
    const [open, setOpen] = useState(false);
    const [startY, setStartY] = useState(null);

    function onScroll(evType, ev) {
        console.log(evType);
        console.log(ev);

        const currentPos = document.documentElement.scrollTop ||
                           document.body.scrollTop;
        const pageY = ev.changedTouches[0].pageY;
        if (evType === 'start' && pageY && currentPos < 20) {
            setStartY(pageY);
            return;
        }
        if (evType === 'end' && startY && pageY && (pageY - startY) > 100) {
            setOpen(true);
            Promise.all([
                onPullDown(),
                new Promise(r => setTimeout(r, 1000))
            ]).then(() => setOpen(false));
        }
        setStartY(null);
    }

    useEffect(() => {
        const touchEnd = (ev) => onScroll('end', ev);
        window.addEventListener('touchend', touchEnd);
        const touchStart = (ev) => onScroll('start', ev);
        window.addEventListener('touchstart', touchStart);

        return () => {
            window.removeEventListener('touchstart', touchStart);
            window.removeEventListener('touchend', touchEnd);
        };
    });

    if (!open) {
        return '';
    }

    return (
        <div className="PullDown" style={{ paddingTop: 30 }}>
            <Loading />
        </div>
    );
}
