import React, { useContext } from 'react';
import './style.css';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyCheckDollar, faChartSimple, faListCheck, faWeightScale } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from 'context/AppContext';
import logo from './img/logo.svg';

function HomeLink() {
    return (
        <Link to="/" className="home-link mobile-hide">
            <img
                type="img/svg+xml"
                src={ logo }
                alt={ logo }
                width="30"
                height="30"
            />
        </Link>
    );
}

function AppNavLink({to, title, icon}) {
    return (
        <NavLink
            to={ to }
            activeClassName="active"
        >
            <div className="nav-link-column">
                <FontAwesomeIcon icon={ icon } className="mobile-show" />
                <div className="nav-link-title" children={ title } />
            </div>
        </NavLink>
    )
}

export function AppHeader() {
    const { currentUser } = useContext(AppContext);

    if (!currentUser) {
        return '';
    }

    return (
        <header className="AppHeader">
            <HomeLink />
            <div className="AppHeaderNavRow">
                <AppNavLink to="/budget" icon={ faMoneyCheckDollar } title="Budgets"/>
                <AppNavLink to="/meters" icon={ faChartSimple } title="Meters"/>
                <AppNavLink to="/fitness" icon={ faWeightScale } title="Fitness"/>
                <AppNavLink to="/lists" icon={ faListCheck } title="Lists"/>
                <a className="nav-link-title mobile-hide" href="/admin/">Admin</a>
            </div>
        </header>
    );
}

export function SimpleAppHeader() {
    return (
        <header className="App-header simple">
            <HomeLink />
        </header>
    );
}
