import React from 'react';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'components/Forms';
import { Row } from 'components/Structure';


export function ReadingsPresets({ params, setParams }) {
    function clickAllTime() {
        setParams({
            bucket: 'year',
            ts__gte: '2018-03-05T00:00:00Z',
            ts__lt: DateTime.local().toISO(),
        });
    }

    function clickToday() {
        setParams({
            bucket: 'hour',
            ts__gte: DateTime.local().startOf('day').toISO(),
            ts__lt: DateTime.local().endOf('day').toISO(),
        });
    }

    function clickPastMonth() {
        setParams({
            bucket: 'day',
            ts__gte: DateTime.local().minus({month: 1}).startOf('day').toISO(),
            ts__lt: DateTime.local().endOf('day').toISO(),
        });
    }

    function clickTwoYears() {
        setParams({
            bucket: 'month',
            ts__gte: DateTime.local().minus({year: 2}).startOf('month').toISO(),
            ts__lt: DateTime.local().endOf('day').toISO(),
        });
    }

    function clickPrev() {
        const diff = DateTime.fromISO(params.ts__lt) - DateTime.fromISO(params.ts__gte);
        setParams({
            ts__gte: DateTime.fromISO(params.ts__gte).minus(diff).toISO(),
            ts__lt: params.ts__gte,
        });
    }

    function clickNext() {
        const diff = DateTime.fromISO(params.ts__lt) - DateTime.fromISO(params.ts__gte);
        setParams({
            ts__gte: params.ts__lt,
            ts__lt: DateTime.fromISO(params.ts__lt).plus(diff).toISO(),
        });
    }

    return (
        <Row className="ReadingsPresets mobile-hide">
            <Button type="link" onClick={ clickPrev }>
                <FontAwesomeIcon icon={ faAngleLeft }/>&nbsp;Prev
            </Button>
            <Button type="link" onClick={ clickToday }>Today</Button>
            <Button type="link" onClick={ clickPastMonth }>Past Month</Button>
            <Button type="link" onClick={ clickTwoYears }>Two Years</Button>
            <Button type="link" onClick={ clickAllTime }>All Time</Button>
            <Button type="link" onClick={ clickNext }>
                Next&nbsp;<FontAwesomeIcon icon={ faAngleRight }/>
            </Button>
        </Row>
    );
}
