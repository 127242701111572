import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './style.css';
import { AutoComplete } from 'components/AutoComplete';
import { PullDown } from 'components/PullDown';
import { Page, Card, Column, SectionTitle } from 'components/Structure';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { services } from 'services';
import { ListItems } from './ListItems';

export function ListDetails({ list, setList }) {
    const [newItem, setNewItem] = useState('');

    if (!list) return '';

    async function submitNewItem(name) {
        setNewItem('');

        const found = list.items.find(i => i.name === name);
        let payload;
        if (found) {
            payload = {
                ...found,
                desired_count: 1,
                list: list.id,
                last_added: new Date().toISOString(),
            };
        } else {
            payload = {
                name: name,
                desired_count: 1,
                list: list.id,
                notes: '',
                last_added: new Date().toISOString(),
            };
        }

        await services.listItems.create(payload).then((resp) => {
            resp.show = true;
            const otherItems = list.items.filter(i => i.name !== resp.name);
            setList((list) => ({
                ...list,
                items: [resp, ...otherItems]
            }));
        });
    }

    function updateItem(item) {
        if (!item.id) return;
        item.name = item.name.trim();
        if (item.name === '') {
            services.listItems.removeById(item.id);
            setList({
                ...list,
                items: list.items.filter(i => i.id !== item.id)
            });
            return;
        }

        // Update the item in place
        const found = list.items.find(i => i.id === item.id);
        found.name = item.name;
        found.checked = item.checked;
        found.show = true;
        found.desired_count = item.desired_count;
        found.completed_count = item.completed_count;
        found.location = item.location;

        setList({...list, items: [...list.items]});
        services.listItems.update({notes: '', ...item, list: list.id});
    }

    function checkItem(item) {
        item.checked = !item.checked;
        item.desired_count = item.checked ? 0 : 1;
        item.completed_count = item.completed_count + (item.checked ? 1 : -1);
        updateItem(item);
    }

    return (
        <Card className="ListDetails">
            <Column>
                <SectionTitle className="mobile-hide">{ list.name }</SectionTitle>
                <div className="new-item">
                    <AutoComplete
                        value={ newItem }
                        dataSource={ list.autocomplete ? list.items.map(i => i.name) : [] }
                        setValue={ setNewItem }
                        onSelect={ submitNewItem }
                    />
                </div>
                <ListItems
                    items={ list.items }
                    list_id={ list.id }
                    group_by_location={ list.group_by_section }
                    checkItem={ checkItem }
                    updateItem={ updateItem }
                />
            </Column>
        </Card>
    );
}

export function loadList(id, setList) {
    return services.lists.getById(id).then((res) => {
        res.items.forEach(i => i.show = i.desired_count > 0);
        setList(res)
    })
}

export function ListDetailsPage() {
    const { id } = useParams();
    const [list, setList] = useState();

    useAlertedEffect(async () => {
        if (list && list.id === id) return;
        return loadList(id, setList);
    }, [id]);

    const refresh = () => loadList(id, setList);

    if (!list) return '';

    return (
        <>
            <PullDown onPullDown={ refresh } />
            <Page title={ list.title }>
                <ListDetails list={ list } setList={ setList } />
            </Page>
        </>
    );
}
