import React, { useState } from 'react';
import { Button, Input } from 'components/Forms';
import { Card, SectionTitle } from 'components/Structure';

export function GoalEntry({goal, setGoal}) {
    const [innerGoal, setInnerGoal] = useState(goal);

    async function clickSave() {
        if (!innerGoal || innerGoal < 100 || innerGoal > 250) return;
        setGoal(innerGoal);
    }

    return (
        <Card className="GoalEntry">
            <SectionTitle>Set Goal</SectionTitle>
            <Input
                type="number"
                value={ innerGoal }
                onChange={ (ev) => setInnerGoal(ev.target.value) }
            />
            <Button type="primary" onClick={ clickSave }>Save</Button>
        </Card>
    )
}
