import React, { useState } from 'react';
import './style.css';
import { DateTime } from 'luxon';
import { Amount } from 'components/Amount';
import { Page, Card, CardRow, Column, SectionTitle } from 'components/Structure';
import { DataTable } from 'components/DataTable';
import { typeMap } from 'constants/types';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { services } from 'services';

function getParams() {
    const now = DateTime.local();
    return {
        group: 'category',
        posted__gt: now.minus({years: 1}).startOf('day').toISO(),
    };
}

function BudgetReportSection({type, transactions, catMap}) {
    const rows = transactions
        .filter((tr) => tr.type === type)
        .sort((a, b) => a.amount > b.amount ? 1 : -1)
        .map((tr) => [
            catMap[tr.category]?.name || 'Unknown',
            <Amount value={ (tr.amount / 12).toFixed(2) } />,
            <Amount value={ (tr.amount / 52).toFixed(2) } />,
        ]);

    return (
        <Card className="BudgetReportSection">
            <SectionTitle>{ typeMap[type] }</SectionTitle>
            <DataTable
                headers={ ['Category', 'Monthly Avg', 'Weekly Avg'] }
                rows={rows}
            />
        </Card>
    );
}

export function BudgetReport() {
    const [transactionAgg, setTransactionAgg] = useState(null);
    const [categories, setCategories] = useState(null);

    useAlertedEffect(async () => {
        if (transactionAgg) return;
        const [categoryResp, aggs] = await Promise.all([
            services.categories.getPage(),
            services.transactions.getAgg(getParams())
        ]);
        setTransactionAgg(aggs);
        setCategories(categoryResp.results);
    }, [transactionAgg]);

    if (!transactionAgg || !categories) return '';
    const catMap = categories.reduce((res, cat) => ({...res, [cat.id]: cat}), {});

    return (
        <Page className="BudgetReport" title="Budget Report">
            <CardRow className="budget-main">
                <Column>
                    <BudgetReportSection
                        type={ 2 }
                        transactions={ transactionAgg }
                        catMap={ catMap }
                    />
                    <BudgetReportSection
                        type={ 3 }
                        transactions={ transactionAgg }
                        catMap={ catMap }
                    />
                </Column>
                <Column>
                    <BudgetReportSection
                        type={ 1 }
                        transactions={ transactionAgg }
                        catMap={ catMap }
                    />

                </Column>
            </CardRow>
        </Page>
    );
}
