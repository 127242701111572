import React, { useState, useEffect } from 'react';
import './style.css';
import './theme.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Alerts } from 'components/Alerts';
import { AppHeader } from 'components/AppHeader';
import { PrivateRoute } from 'components/PrivateRoute';
import { AppContext } from 'context/AppContext';
import { BudgetDetails } from 'pages/BudgetDetails';
import { BudgetHome } from 'pages/BudgetHome';
import { BudgetReport } from 'pages/BudgetReport';
import { BudgetTransactionsList } from 'pages/BudgetTransactionsList';
import { BudgetTransactionsReview } from 'pages/BudgetTransactionsReview';
import { FitnessHome } from 'pages/FitnessHome';
import { FitnessRoutine } from 'pages/FitnessRoutine';
import { ListsHome } from 'pages/ListsHome';
import { ListDetailsPage } from 'pages/ListDetails';
import { Home } from 'pages/Home';
import { Login } from 'pages/Login';
import { Meters } from 'pages/Meters';
import { services } from 'services';

function App() {
    const [user, setUser] = useState(services.auth.getUserData());
    const [profile, setProfile] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const context = {
        currentUser: user,
        refreshUser: () => setUser(services.auth.getUserData()),
        profile: profile,
        updateProfile: (p) => setProfile({...profile, ...p}),
        addAlert: (type, message) => {
            if (alerts.find(a => a.message === message)) return;
            alerts.push({type, message, ts: Date.now()});
            setAlerts([...alerts]);
        }
    };

    // Use the user id instead of the object to prevent duplicate requests
    const user_id = user ? user.user_id : null;
    useEffect(() => {
        if (!user_id) return;
        services.users
            .getById(user_id)
            .then((u) => setProfile(u.profile));
    }, [user_id]);

    const routes = (
        <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/budget/review/:id" component={BudgetTransactionsReview} />
            <PrivateRoute path="/budget/review" component={BudgetTransactionsReview} />
            <PrivateRoute path="/budget/transactions" component={BudgetTransactionsList} />
            <PrivateRoute path="/budget/report" component={BudgetReport} />
            <PrivateRoute path="/budget/:account_id/:year/:month" component={BudgetDetails} />
            <PrivateRoute path="/budget" component={BudgetHome} />
            <PrivateRoute path="/fitness/routines/:id" component={FitnessRoutine} />
            <PrivateRoute path="/fitness" component={FitnessHome} />
            <PrivateRoute path="/lists/:id" component={ListDetailsPage} />
            <PrivateRoute path="/lists" component={ListsHome} />
            <PrivateRoute path="/meters" component={Meters} />
            <PrivateRoute path="/" component={Home} />
        </Switch>
    );

    return (
        <div className="App">
            <AppContext.Provider value={context}>
                <Router>
                    <AppHeader />
                    <Alerts alerts={ alerts } setAlerts={ setAlerts }/>
                    <div className="AppContent">
                        { routes }
                    </div>
                </Router>
            </AppContext.Provider>
        </div>
    );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
