// Set location
// Set count
// Add notes
// Delete

import React from 'react';
import { Modal } from 'components/Modal';
import { Input, Button } from 'components/Forms';
import { Card } from 'components/Structure';


export function ItemEditModal({item, updateItem, setEditItem}) {
    if (!item) return '';

    function conditionallySet(ev) {
        if (ev && ev.code !== 'Enter') return;
        item.location = (item.location || '').trim();
        updateItem(item);
        setEditItem(null);
    }

    return (
        <Modal
            title={ item.name }
            show={ true }
            close={ () => setEditItem(null) }
            body={
                <Card>
                    <Input
                        label="Location"
                        value={ item.location }
                        onChange={ (ev) => setEditItem({...item, location: ev.target.value }) }
                        onKeyUp={ conditionallySet }
                    />
                    <Input
                        label="Amount"
                        type="number"
                        value={ item.desired_count }
                        onChange={ (ev) => setEditItem({...item, desired_count: ev.target.value}) }
                    />
                    <Button type="primary"
                        onClick={ () => conditionallySet() }
                        children="Save"
                    />
                </Card>
            }
        />
    );
}
