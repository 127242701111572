import ReactECharts from 'echarts-for-react';
import React from 'react';
import { Card } from 'components/Structure';

export function WeightChart({aggs, goal}) {
    if (!aggs) {
        return <Card style={{ minHeight: '220px', width: '100%' }} >&nbsp;</Card>;
    }
    const aggMin = aggs.reduce((p, a) => Math.min(a.value, p), 10000);
    const min = Math.min(aggMin, goal) - 5;

    const options = {
      tooltip: {
        show: true,
        trigger: 'axis',
      },
      xAxis: {
        type: 'time',
      },
      grid: {
        top: 10,
        left: 30,
        right: 10,
        bottom: 30
      },
      yAxis: {
        type: 'value',
        max: 'dataMax',
        min: min,
        splitLine: { show: false },
      },
      series: [
        {
          animationDuration: 500,
          data: aggs.map((d) => [d.ts, Math.round(d.value * 10) / 10]),
          type: 'line',
          symbol: 'circle',
          symbolSize: 6
        },
        {
          animationDuration: 500,
          data: [
            [aggs[0]?.ts || 0, goal],
            [aggs.slice(-1)[0]?.ts || 0, goal]
          ],
          type: 'line'
        }
      ]
    };

    return (
        <Card className="WeightChart mobile-hide">
            <ReactECharts
                option={options}
                style={{ height: '220px', width: '100%' }}
            />
        </Card>
    );
}
