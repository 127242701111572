import React from 'react';
import './style.css';
import { Menu } from 'components/AppHeader/Menu';

function divFactory(cn) {
    return function({className, ...rest}) {
        if (!rest.children) return '';
        return (
            <div
                className={cn + (className ? ' ' + className : '')}
                {...rest}
            />
        )
    };
}

export const Row = divFactory('Row');
export const Column = divFactory('Column');
export const Card = divFactory('Card');
export const CenterCard = divFactory('CenterCard');
export const PageTitle = divFactory('PageTitle');
export const SectionTitle = divFactory('SectionTitle');
export const Description = divFactory('Description');
export const CardRow = divFactory('CardRow');

export function Placeholder({className, condition, message, ...rest}) {
    if (!condition) return message || '';
    return <div
        className={'Placeholder' + (className ? ' ' + className : '')}
        {...rest}
    />;
}

export function SidePanelList({ left, right, className }) {
    return (
        <div className={ 'SidePanelList' + (className ? ' ' + className : '') }>
            <div className="left">{left}</div>
            <div className="right">{right}</div>
        </div>
    );
}

export function Page({ title, className, children }) {
    return (
        <div className={ 'Page' + (className ? ' ' + className : '') }>
            <Row className="PageTitleRow">
                <PageTitle children={ title } />
                <Menu />
            </Row>
            { children }
        </div>
    );
}
