import React, { useState } from 'react';
import './style.css';
import { Page, Card, CardRow, SectionTitle, Column } from 'components/Structure';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { services } from 'services';
import { GoalEntry } from './GoalEntry';
import { RoutineList } from './RoutineList';
import { WeightChart } from './WeightChart';
import { WeightEntry } from './WeightEntry';
import { WeightTable } from './WeightTable';

export function FitnessHome() {
    const [aggs, setAggs] = useState(null);
    const [page, setPage] = useState(null);
    const [user, setUser] = useState(null);

    useAlertedEffect(() => {
        if (aggs) return;
        return services.weights.getAgg().then(res => setAggs(res))
    }, [aggs]);

    useAlertedEffect(() => {
        if (page) return;
        return services.weights.getPage({per_page: 20}).then(res => setPage(res))
    }, [page]);

    useAlertedEffect(() => {
        if (user) return;
        const uid = services.auth.getUserData().user_id;
        return services.users.getById(uid).then(user => setUser(user))
    }, [user])

    function setGoal(weight_goal) {
        const updated = {
            ...user,
            profile: {...user.profile, weight_goal}
        };
        services.users.update(updated);
        setUser(updated);
    }

    function refresh(params = {}) {
        services.weights.getAgg().then(res => setAggs(res));
        services.weights.getPage({...params, per_page: 20}).then(res => setPage(res));
    }

    if (!user || !page) return '';

    const last = (aggs || [])[0];

    return (
        <Page className="FitnessHome" title="Fitness">
            <WeightChart aggs={ aggs } goal={ user?.profile?.weight_goal }/>
            <CardRow className="data-row">
                <WeightTable page={ page } refresh={ refresh }/>
                <Column>
                    <Card className="mobile-show">
                        <SectionTitle children="Last Average" />
                        <div style={{ fontSize: '24px' }} children={ last?.value?.toFixed(1) || 'None' } />
                        <div style={{ fontSize: '10px' }} children={ last?.ts || 'None' } />
                    </Card>
                    <WeightEntry page={ page } refresh={ refresh }/>
                    <GoalEntry
                        goal={ user?.profile?.weight_goal }
                        setGoal={ setGoal }
                    />
                    <RoutineList />
                </Column>
            </CardRow>
        </Page>
    );
}
