import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { parse } from 'querystring';
import './style.css';
import { types } from 'constants/types';
import { Amount } from 'components/Amount';
import { Input, CheckBox, Select, Button, ButtonRow } from 'components/Forms';
import { PageTitle, CenterCard, Row } from 'components/Structure';
import { services } from 'services';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { sortBy } from 'helpers';

function FormRow({className, label, content}) {
    return (
        <div className={ 'FormRow' + (className ? ` ${className}` : '') }>
            <div className="form-row-label" children={ label } />
            <div className="form-row-content" children={ content } />
        </div>
    );
}

export function BudgetTransactionsReview({ location }) {
    const [loading, setLoading] = useState(true);
    const [current, setCurrent] = useState(null);
    const [content, setContent] = useState(null);
    const { id } = useParams();
    const history = useHistory();
    const query = parse(location.search);
    const now = new Date();
    const next = query.next || `/budget/${now.getFullYear()}/${now.getMonth() + 1}`;

    useAlertedEffect(() => {
        if (content) return;

        async function getTransactions() {
            if (id) {
                const tr = await services.transactions.getById(id);
                return [tr];
            }
            const since = new Date(Date.now() - (90 * 24 * 60 * 60 * 1000)).toISOString();

            const [nctResp, nttResp] = await Promise.all([
                services.transactions.getPage({
                    category__isnull: true,
                    posted__gte: since,
                }),
                services.transactions.getPage({
                    category__isnull: false,
                    type__isnull: true,
                    posted__gte: since,
                }),
            ]);

            const transactions = nctResp.results.concat(nttResp.results);
            return sortBy(transactions, 'date');
        }

        return Promise.all([
            getTransactions(),
            services.categories.getPage(),
            services.rules.getPage(),
        ]).then(([transactions, catResp, ruleResp]) => {
            const categories = [{display: '', value: 0}, ...catResp.results];
            const selectTypes = [{display: '', value: 0}, ...types];
            transactions.forEach((tr) => {
                tr.rule_id = ruleResp.results.find(r => r.payee === tr.payee)?.id;
            });

            const current = transactions.shift();
            setContent({
                queue: transactions,
                categories,
                types: selectTypes,
                rules: ruleResp.results,
                set_rule: Boolean(current?.rule_id),
            });
            setCurrent(current);
            setLoading(false);
            if (!current) {
                history.push(next, {});
                return;
            }
            setTimeout(() => document.querySelector('.Memo input').focus(), 100);
        });
    }, [content, id]);

    if (!content || !current) return '';

    function update(attr, value) {
        setCurrent({...current, [attr]: value || null});
    }

    function nextTransaction() {
        if (content.queue.length === 0) {
            history.push(next, {});
            return;
        }
        const current = content.queue.shift();
        setCurrent(current)
        setContent({...content, set_rule: Boolean(current.rule_id)});
        setTimeout(() => document.querySelector('.Memo input').focus(), 100);
    }

    async function save() {
        setLoading(true);
        if (content.set_rule && !current.rule_id) {
            await services.rules.create({
                account: current.account.id,
                payee: current.payee,
                category: current.category,
                type: current.type
            });
        } else if (current.rule_id && !content.set_rule) {
            await services.rules.remove({id: current.rule_id});
        }

        try {
            await services.transactions.update(current);
            nextTransaction();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <CenterCard className="BudgetTransactionsReview">
            <div className="title-row">
                <PageTitle children="Review Transactions"/>
                { content.queue.length ? <div>{ content.queue.length } left</div> : '' }
            </div>
            <FormRow
                label="Date"
                content={ new Date(current.posted).toLocaleDateString() }
            />
            <FormRow
                label="Payee"
                content={ current.payee }
            />
            <FormRow
                label="Amount"
                content={ <Amount value={ current.amount } /> }
            />
            <FormRow
                label="Transaction Type"
                className="form-row-separator"
                content={ current.transaction_type }
            />
            <FormRow
                className="Memo"
                label="Memo"
                content={
                    <Input
                        value={ current.memo }
                        onChange={ (ev) => update('memo', ev.target.value) }
                    />
                }
            />
            <FormRow
                label="Category"
                content={
                    <Select
                        value={ current.category || 0 }
                        onChange={ (ev) => update('category', ev.target.value) }
                        choices={ content.categories }
                        select="id"
                        display="name"
                    />
                }
            />
            <FormRow
                label="Type"
                content={
                    <Select
                        value={ current.type || 0 }
                        onChange={ (ev) => update('type', ev.target.value) }
                        choices={ content.types }
                        select="id"
                        display="name"
                    />
                }
            />
            <ButtonRow>
                <Button
                    type="primary"
                    disabled={ loading }
                    onClick={ save }
                    children="Save"
                />
                <Button
                    type="outline"
                    disabled={ loading }
                    onClick={ nextTransaction }
                    children={ id ? 'Cancel' : 'Next' }
                />
            </ButtonRow>
            <Row className="rule-section">
                <CheckBox
                    value={ content.set_rule }
                    onClick={ () => setContent({...content, set_rule: !content.set_rule }) }
                    label="Use as a Rule"
                />
            </Row>
        </CenterCard>
    );
}
