import React, { useState, useEffect } from 'react';
import './style.css';
import { useParams } from 'react-router-dom';
import { DataTable } from 'components/DataTable';
import { CenterCard, Column } from 'components/Structure';
import { Button } from 'components/Forms';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { services } from 'services';
import { speak } from 'services/speech';
import { RunningMode } from './RunningMode';

export function FitnessRoutine() {
    const { id } = useParams();
    const [mode, setMode] = useState('fetching');
    const [routine, setRoutine] = useState();

    useAlertedEffect(() => {
        if (routine) return;
        return services.routines.getById(id).then(res => {
            res.total = res.movement_set.reduce((p, m) => {
                m.start = p;
                return p + m.duration + m.cooldown;
            }, 0);
            setRoutine(res);
            setMode('ready');
        });
    }, [routine]);

    useEffect(() => {
        if (mode === 'complete') {
            speak('All done. Nice work!');
        }
    }, [mode]);

    if (mode === 'fetching') {
        return '';
    }

    let component;
    if (mode === 'ready') {
        component = (
            <Column className="ready">
                <DataTable rows={
                    [
                        ['Moves', routine.movement_set.length],
                        ['Time', Math.ceil(routine.total / 60) + ' minutes']
                    ]
                }/>
                <Button
                    type="primary"
                    onClick={ () => setMode('running') }
                    children="Start"
                />
            </Column>
        );
    } else if (mode === 'running') {
        component = <RunningMode routine={ routine } setMode={ setMode } />
    } else if (mode === 'complete') {
        component = (
            <div className="complete">
                { 'Congratulations!' }
            </div>
        )
    }

    return (
        <CenterCard className="FitnessRoutine">
            <div className="header" children={ routine.name } />
            { component }
        </CenterCard>
    );
}
