import { makeMap } from 'helpers';

export const types = [
    {id: 1, name: 'Budgeted Expense'},
    {id: 2, name: 'Unbudgeted Expense'},
    {id: 3, name: 'Income'},
    {id: 4, name: 'Transfer'},
];

export const typeMap = makeMap(types);
