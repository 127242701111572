import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './style.css';
import { stringify } from 'querystring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group';
import { DateTime } from 'luxon';
import { Amount } from 'components/Amount';
import { DataTableRow, DataTable } from 'components/DataTable';
import { Button } from 'components/Forms';
import { Row, Card } from 'components/Structure';
import { LinkedSectionTitle } from '../LinkedSectionTitle';
import { Total } from '../Total';
import { groupBy, agg, orderBy } from 'helpers';

function EstimateRow({ estimate, categories, setEditEstimate }) {
    return (
        <Row className="EstimateRow" onClick={ () => setEditEstimate(estimate) }>
            <div>{ estimate.description }</div>
            <div className="estimate-amount">${ estimate.amount.toFixed(2) }</div>
        </Row>
    );
}

function EstimateList({ estimates, categories, setEditEstimate }) {
    const rows = estimates.map((estimate, index) => (
        <EstimateRow
            estimate={ estimate }
            setEditEstimate={ setEditEstimate }
            key={ `est-${index}` }
        />
    ));
    function addEstimate() {
        setEditEstimate({
            budget: estimates[0].budget,
            category: estimates[0].category,
            type: estimates[0].type
        });
    }

    return (
        <div className="EstimateList">
            { rows }
            <Button type="outline small" onClick={ addEstimate } >
                <FontAwesomeIcon icon={ faPlus }/>
            </Button>
        </div>
    )
}

function EstimateBar({estimates, estimated, actual, ...rest}) {
    const cname = estimates[0]?.type === 3 ? 'success' :
                  actual / estimated > 1.5 ? 'danger' :
                  actual / estimated > 1.1 ? 'warning' : 'success';
    const width = Math.min(100 * (actual / estimated), 100);
    return (
        <div className={ 'EstimateBar ' + cname } {...rest}>
            <div className="inner-bar" style={{ width: width + '%' }} />
        </div>
    );
}

function CategoryEstimates({estimates, name, qs, categories, estimated, actual, setEditEstimate}) {
    const [showEstimates, setShowEstimates] = useState(false);

    return (
        <div className="CategoryEstimates">
            <LinkedSectionTitle name={ name } qs={ qs } className="subsection"/>
            <Row>
                <Total name="Estimated" value={ estimated }/>
                <Total name="Actual" value={ actual } />
            </Row>
            <EstimateBar
                estimates={ estimates }
                estimated={ estimated }
                actual={ actual }
                onClick={ () => setShowEstimates(!showEstimates) }
            />
            <CSSTransition
                in={showEstimates}
                timeout={400}
                classNames='openSection'
                unmountOnExit>
                <EstimateList
                    estimates={ estimates }
                    setEditEstimate={ setEditEstimate }
                />
            </CSSTransition>
        </div>
    );
}

function UnestimatedTransactions({transactions}) {
    const history = useHistory();
    if (transactions.length === 0) return '';

    const rows = transactions.map((t, i) => (
        <DataTableRow
            row={ [
                DateTime.fromISO(t.posted).toFormat('MM/dd/yy'),
                t.payee,
                t.memo,
                <Amount value={ t.amount } />
            ] }
            key={ `tr-${i}` }
            onClick={ () => history.push(`/budget/review/${t.id}`, {}) }
            style={{ cursor: 'pointer' }}
        />
    ));

    return (
        <div className="UnestimatedTransactions">
            <div className="subtitle">
                Unestimated Transactions
            </div>
            <DataTable
                headers={ [] }
                rows={ rows }
            />
        </div>
    );
}


export function EstimateSection({ type, name, start, end, content, setEditEstimate }) {
    const est = content.budget.estimate_set.filter(e => e.type === type);
    const total = agg(est, {});
    const typeTransactions = content.transactions.filter(t => t.type === type);
    const actual = Math.abs(agg(typeTransactions, {muted: false}));
    const grouped = groupBy(est, 'category');
    const params = {
        type,
        posted__gte: start.toISO(),
        posted__lt: end.toISO(),
    };
    const qs = stringify(params);

    const categoryPayloads = Object.keys(grouped).map((category) => ({
        key: `est-${type}-${category}`,
        estimates: grouped[category],
        name: content.catMap[category].name,
        qs: stringify({...params, category}),
        estimated: agg(grouped[category], {}),
        actual: Math.abs(agg(typeTransactions, {category: parseInt(category)})),
        order: content.catMap[category].variable ? 1 : -1,
    }));

    const categoryEstimates = orderBy(categoryPayloads, ['order', 'estimated'])
        .map(payload => <CategoryEstimates setEditEstimate={ setEditEstimate } {...payload}/>);

    const unestimated = typeTransactions.filter(t => !grouped[t.category]);

    return (
        <Card className="EstimateSection">
            <LinkedSectionTitle name={ name } qs={ qs }/>
            <Total name="Estimated"  value={ total } />
            <Total name="Actual" value={ actual } />
            <UnestimatedTransactions transactions={ unestimated } />
            { categoryEstimates }
        </Card>
    )
}
