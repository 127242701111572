import React from 'react';
import './style.css';

export function DataTableRow({row, ...rest}) {
    const columns = row.map((c, i) => <td key={ `td-${i}` }>{ c }</td>);
    return <tr {...rest}>{ columns }</tr>;
}


export function DataTable({headers, rows}) {
    const headerCols = (headers || []).map((h, i) => <th key={ `th-${i}` }>{ h }</th>);
    const compiledRows = rows.map((r, i) => {
        if (Array.isArray(r)) {
            return <DataTableRow key={ `tr-${i}` } row={ r }/>
        }
        return r;
    });

    return (
        <table className="DataTable">
            { headerCols.length ?
                <thead>
                    <tr>
                        { headerCols }
                    </tr>
                </thead> :
                <></>
            }
            <tbody>
                { compiledRows }
            </tbody>
        </table>
    );
}
