import React from 'react';
import { Button } from 'components/Forms';
import { Row } from 'components/Structure';

export function Pagination({page, per_page, count, onChange}) {
    let next = '';
    if (page * per_page < count) {
        next = <Button type="outline" onClick={ () => onChange(page + 1) }>Next</Button>;
    }
    let prev = '';
    if (page > 1) {
        prev = <Button type="outline" onClick={ () => onChange(page - 1) }>Prev</Button>;
    }
    const justifyContent = prev && next ? 'space-between' :
                           next ? 'flex-end' : 'flex-start';

    return (
        <Row style={{ justifyContent, marginTop: '10px' }}>
            { prev }
            { next }
        </Row>
    );
}
