const speech = new SpeechSynthesisUtterance();
speech.lang = "en";

export function speak(text) {
    if (!speech.voice) {
        speech.voice = window.speechSynthesis.getVoices().find(v => v.name === 'Daniel');
    }
    speech.text = text;
    window.speechSynthesis.speak(speech);
}

