import React, { useState } from 'react';
import './style.css';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { Page, Column, CardRow, Card } from 'components/Structure';
import { useAlertedEffect } from 'hooks/useAlertedEffect';
import { services } from 'services';
import { CreateBudget } from './CreateBudget';
import { EstimateEditModal } from './EstimateEditModal';
import { EstimateSection } from './EstimateSection';
import { SummaryTable } from './SummaryTable';
import { TitleCard } from './TitleCard';
import { TransactionSection } from './TransactionSection';

async function fetchContent({ account_id, year, month, setContent }) {
    const [start, end] = getRange(year, month);
    const [transactionResp, categoryResp, budgetResp] = await Promise.all([
        services.transactions.getPage({
            account_id,
            posted__gte: start.toISO(),
            posted__lt: end.toISO(),
        }),
        services.categories.getPage(),
        services.budgets.getPage({account_id, year, month}),
    ]);

    const categories = categoryResp.results;
    const transactions = transactionResp.results;
    const uncat = transactions.filter((t) => !t.category).length;
    const untyped = transactions.filter((t) => t.category && !t.type).length;
    const catMap = categories.reduce((res, cat) => ({...res, [cat.id]: cat}), {});

    setContent({
        key: `${year}/${month}`,
        transactions,
        categories,
        catMap,
        unprocessed: uncat + untyped,
        budget: budgetResp.results[0],
    });
}

function getRange(year, month) {
    const start = DateTime.local(parseInt(year), parseInt(month));
    const end = new DateTime(start).plus({months: 1}).startOf('month');
    return [start, end];
}

export function BudgetDetails() {
    const { account_id, year, month } = useParams();
    const [content, setContent] = useState(null);
    const [editEstimate, setEditEstimate] = useState(null);

    useAlertedEffect(() => {
        if (content?.key === `${year}/${month}`) return;
        return fetchContent({ account_id, year, month, setContent });
    }, [content, year, month]);

    const [start, end] = getRange(year, month);
    const title = `Budget – ${start.toFormat('MMM, y')}`;

    if (!content) return '';
    if (!content.budget) {
        return (
            <Page className="BudgetDetails" title={ title }>
                <CardRow>
                    <TitleCard
                        accountId={ account_id }
                        start={ start }
                        end={ end }
                        unprocessed={ 0 }
                    />
                    <CreateBudget
                        account_id={ account_id }
                        year={ year }
                        month={ month }
                        refresh={ () => fetchContent({ account_id, year, month, setContent }) }
                    />
                </CardRow>
            </Page>
        );
    }

    function updateContent(updates) {
        setContent({...content, ...updates});
    }

    return (
        <Page className="BudgetDetails" title={ title }>
            <CardRow>
                <TitleCard
                    accountId={ account_id }
                    start={ start }
                    end={ end }
                    unprocessed={ content.unprocessed }
                    budget={ content.budget }
                    setEditEstimate={ setEditEstimate }
                />
                <Card style={{ flexGrow: 1 }}>
                    <SummaryTable
                        transactions={ content.transactions }
                        estimates={ content.budget.estimate_set }
                    />
                </Card>
            </CardRow>
            <CardRow>
                <Column className="row-column">
                    <TransactionSection
                        type={ 2 }
                        start={ start }
                        end={ end }
                        name="Unbudgeted Expenses"
                        transactions={ content.transactions }
                        updateContent={ updateContent }
                    />
                    <EstimateSection
                        type={ 3 }
                        start={ start }
                        end={ end }
                        name="Income"
                        content={ content }
                        setEditEstimate={ setEditEstimate }
                    />
                    <TransactionSection
                        type={ 4 }
                        start={ start }
                        end={ end }
                        name="Transfers"
                        transactions={ content.transactions }
                        updateContent={ updateContent }
                    />
                </Column>
                <Column className="row-column">
                    <EstimateSection
                        type={ 1 }
                        start={ start }
                        end={ end }
                        name="Budgeted Expenses"
                        content={ content }
                        setEditEstimate={ setEditEstimate }
                    />
                </Column>
            </CardRow>
            <EstimateEditModal
                editEstimate={ editEstimate }
                setEditEstimate={ setEditEstimate }
                categories={ content.categories }
                refresh={ () => fetchContent({ account_id, year, month, setContent }) }
            />
        </Page>
    );
}
